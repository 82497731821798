<template>
  <div v-if="show">
    <div :id="id" :style="modal">
      <span class="close" @click="toggleModal">&times;</span>
      <img class="modal-content" :src="image" />
      <div id="caption" v-html="caption"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UelloImageModal',

  props: {
    show: {
      type: Boolean,
    },
    image: {
      type: String,
    },
    id: {
      type: String,
    },
    caption: {
      type: String,
    },
  },

  computed: {
    modal() {
      return {
        display: this.show ? 'block' : 'none',
        position: 'fixed',
        'z-index': 30000000,
        'padding-top': '100px',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        'background-color': 'rgba(0, 0, 0, 0.9)',
      };
    },
  },
  methods: {
    toggleModal() {
      this.$emit('close', true);
    },
  },
};
</script>

<style lang="scss" scoped>
#image {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#image:hover {
  opacity: 0.7;
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>
