<template>
  <div>
    <UelloImageModal
      :show="showModal"
      :id="modalId"
      :image="modalImage"
      @close="showModal = false"
    />
    <div
      class="history-item d-flex"
      v-for="(item, index) in history"
      :key="`history-item-${index}`"
    >
      <div class="history-info flex-1 text-left">
        <div>
          <div v-for="(mt, j) in item.months" :key="j">
            <div v-for="(ds, k) in mt.days" :key="k">
              <div class="date">
                <div v-if="k === 0">{{ item.year }}</div>
                <div class="monthDay">{{ mt.month }}/{{ ds.day }}</div>
              </div>
              <div v-for="(hs, i) in ds.items" :key="i" class="card active">
                <p class="status" v-html="hs.message"></p>
                <span class="userHist">{{ hs.user }}</span>
                <div v-if="hs.image">
                  <div v-for="(image, j) in hs.image" :key="j">
                    <div class="image" @click="setModal(true, `img-${j}`, image, '')">
                      <img :src="image" />
                    </div>
                  </div>
                </div>
                <small>{{ formatDate(hs.date) }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import UelloImageModal from '../u-image-modal/UelloImageModal.vue';

export default {
  name: 'history-item',
  components: { UelloImageModal },
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    history: {
      type: Array,
      default: () => [],
    },
    isUnique: {
      type: Boolean,
      default: () => false,
    },
    isLast: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    showModal: false,
    modalId: null,
    modalImage: null,
    modalCaption: null,
  }),

  computed: {
    isErrror() {
      if (Object.prototype.hasOwnProperty.call(this.item, 'insuccess')) {
        return this.item.insuccess;
      }
      return false;
    },
  },

  methods: {
    formatDate(date) {
      return format(new Date(date.date), 'HH:mm:ss');
    },
    setModal(show, id, url, message) {
      this.showModal = show;
      this.modalId = id;
      this.modalImage = url;
      this.modalCaption = message;
    },
  },
};
</script>

<style lang="scss">
.history-item {
  // padding: 10px 20px;
  margin-bottom: 10px;
  // box-shadow: 0px 0px 10px #0002;
}

.uello-circle-check {
  width: 18px;
  height: 18px;
  background: #fff;
  border: 3px solid var(--primary);
  border-radius: 50%;
  display: inline-table;
  content: '';
}

.history-step {
  width: 25px;
}

.flex-1 {
  flex: 1;
}

.history-icon {
  /* font-size: 24px; */

  &.last {
    transform: translateY(-6px);
  }
}

.history-info {
  line-height: 1rem;

  .status {
    font-weight: 600;

    &,
    small {
      color: #4b4b4d;
    }
  }
}

img {
  max-width: 300px;
}
.line {
  width: 2px;
  height: 100%;
  background-color: var(--primary);
}
.card {
  padding: 10px 20px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px #0002;
  border-left: 6px solid #d3d3d3;
}
.active {
  border-left-color: #e63b4f;
}
.date {
  font-size: 14px;
  font-weight: bold;
}
.monthDay {
  padding-left: 5px;
  margin-bottom: 10px;
}
.userHist {
  margin-bottom: 5px;
  font-size: 14px;
}
.image {
  width: 80%;
}
</style>
